export default (dateTime) => {
  // 指定日期和时间
  var EndTime = new Date(dateTime);
  // 当前系统时间
  var NowTime = new Date();
  var t = EndTime.getTime() - NowTime.getTime();
  var d = Math.floor(t / 1000 / 60 / 60 / 24);
  var h = Math.floor(t / 1000 / 60 / 60 % 24);
  var m = Math.floor(t / 1000 / 60 % 60);
  var s = Math.floor(t / 1000 % 60);
  console.log('s',EndTime, t,d,h,m,s)
  if(d > 0) return `还有${d}天结束`
  if(h > 0) return `还有${h}小时结束`
  if(m > 0) return `还有${m}分钟结束`
  if(s > 0) return `还有${s}秒结束`
  return `投票已结束`
}