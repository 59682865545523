<template>
  <div class="vote" v-if="detail">
    <div class="top">
      <div class="title">{{ detail.title }}</div>
      <div ref="contentText" class="content">
        {{ detail.desc }}
      </div>
    </div>
    <div class="options" :class="result" v-if="detail.vote_type === 1">
      <div class="option" v-for="item of detail.options" :key="item.id" :class="onId.indexOf(item.id) > -1 && 'on'" @click="clickId(item)">
        <div class="in">
          <p :class="status">{{ item.title }}</p>
          <div class="percent" :style="{ width: result ? dealNum(item.vote_num) : 0 }"></div>
          <div class="voteNum" :class="result && 'show'">{{ item.vote_num }}</div>
        </div>
      </div>
    </div>
    <div class="pkOptions" :class="result" v-if="detail.vote_type === 2">
      <div class="option" v-for="item of detail.options" :key="item.id" :class="onId[0] === item.id && 'on'" @click="pkClickId(item)">
        <div class="in">
          <p :class="status">{{ item.title }}</p>
          <!-- <div class="percent" :style="{width: result ? dealNum(item.vote_num) : 0}"></div>
          <div class="voteNum" :class="result && 'show'">{{item.vote_num}}</div> -->
        </div>
      </div>
      <div class="vs"></div>
    </div>
    <div class="vsLine" :class="result && 'show'" v-if="detail.vote_type === 2">
      <div class="left">{{ detail.options[0].vote_num }}</div>
      <div class="line">
        <div class="leftLine" :class="onId === 0 && 'on'" :style="{ width: result ? dealNum(detail.options[0].vote_num, true) : 0 }"></div>
        <div class="rightLine" :class="onId === 1 && 'on'" :style="{ width: result ? dealNum(detail.options[1].vote_num, true) : 0 }"></div>
      </div>
      <div class="right">{{ detail.options[1].vote_num }}</div>
    </div>
    <div class="people" v-if="detail.show_join_num">{{ allNumber }}人参与，{{ detail.end_time_stamp | getDistanceSpecifiedTime }}</div>
    <div v-if="detail.vote_type === 1" class="button" @click="submit" :class="result && 'hide'">提 交</div>
  </div>
</template>

<script>
  import { getVoteDetail, dovote } from "@/utils/api";
  import getDistanceSpecifiedTime from "@/utils/getDistanceSpecifiedTime";

  export default {
    name: "vote",
    data() {
      return {
        detail: null,
        onId: [],
        status: "",
        aboutTextflag: false,
        result: "",
      };
    },
    filters: { getDistanceSpecifiedTime },
    computed: {
      allNumber() {
        let all = 0;
        this.detail.options.map(item => {
          all = all + item.vote_num;
        });
        console.log("all", all);
        return all;
      },
      dealNum() {
        return (num, pk) => {
          return this.allNumber ? (num / this.allNumber) * 100 - (pk ? 3 : 0) + "%" : 0;
        };
      },
      id() {
        return this.$route.query.id;
      },
      fake() {
        return this.$route.query.fake;
      },
    },
    created() {
      this.getVoteDetail(this.id);
    },
    methods: {
      async getVoteDetail(id) {
        const res = await getVoteDetail(id);
        console.log("res", res);
        this.detail = res.data.data;
        this.$nextTick(() => {
          const fontSize = 37;
          const WIDTH = 100;
          if (this.detail.desc.length - 3 * (WIDTH / fontSize) > 0) {
            this.aboutTextflag = true;
          }
        });
      },
      clickId(item) {
        if (this.result) {
          return;
        }
        if (this.detail.chose_num === 1) {
          this.onId = [item.id];
        } else if (this.onId.indexOf(item.id) > -1) {
          this.onId.splice(this.onId.indexOf(item.id), 1);
        } else if (this.onId.length >= this.detail.chose_num) {
          return;
        } else {
          this.onId.push(item.id);
        }
      },
      pkClickId(item) {
        this.clickId(item);
        this.submit();
      },
      async submit() {
        if (this.result) {
          return;
        }
        if (this.onId.length === 0) {
          return;
        }
        this.detail.options.forEach((item, index) => {
          if (this.onId.indexOf(item.id) > -1) {
            this.detail.options[index].vote_num++;
          }
        });
        this.result = "result";
        if (!this.fake) {
          await this.vote();
        }
      },
      async vote() {
        const { errcode, errmsg } = await dovote({
          vote_id: this.detail.id,
          options: this.onId.join(","),
        });
        if (errcode !== 1) {
          //alert(errmsg)
          console.log(errmsg);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .vote {
    background-color: #fff;
    padding: 0.4rem;

    .top {
      .title {
        font-size: 0.5rem;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 0.53rem;
        color: #373737;
        margin-bottom: 0.11rem;
      }
      .content {
        font-size: 0.37rem;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 0.53rem;
        color: rgb(51, 51, 51);
        margin-bottom: 0.4rem;
        display: -webkit-box;

        &.hide {
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          position: relative;
        }
      }
      .showMore {
        font-size: 0.37rem;
        text-align: center;
        color: blue;
        padding: 0;
        margin-bottom: 30px;
        cursor: pointer;
      }
    }

    .options {
      .option {
        background-color: #fff;
        border: 1px solid #d8d8d8;
        margin-bottom: 0.4rem;
        border-radius: 2px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -ms-border-radius: 2px;
        -o-border-radius: 2px;
        border-radius: 2px;

        .in {
          padding: 0.19rem 1rem;
          transition: all 1.5s;
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          text-align: center;

          p {
            font-size: 0.43rem;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 0.59rem;
            color: #3e3e3e;
            opacity: 1;
            width: 80%;
            z-index: 2;
          }
          .percent {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transition: all 1.5s;
            background-color: #dddddd;
            z-index: 1;
          }
          .voteNum {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0.27rem;
            margin: auto;
            transition: all 1.5s;
            font-size: 0.43rem;
            line-height: 0.43rem;
            height: 0.43rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #3e3e3e;
            opacity: 0;

            &.show {
              opacity: 1;
            }
          }
        }

        &.on {
          border: 1px solid #f04040;

          p {
            color: #f04040;
          }
          .percent {
            background-color: #feebeb;
          }
          .voteNum {
            color: #f04040;
          }
        }
      }

      &.result {
        .in {
          justify-content: flex-start;
          padding: 0.16rem 0.4rem;
        }
      }
    }
    .people {
      font-size: 0.32rem;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 0.53rem;
      color: #8f8f8f;
      margin-bottom: 0.27rem;
    }
    .button {
      width: 3.65rem;
      height: 0.88rem;
      line-height: 0.88rem;
      background: #f04040;
      opacity: 1;
      border-radius: 0.44rem;
      text-align: center;
      color: #fff;
      font-size: 0.37rem;
      font-family: PingFang SC;
      font-weight: 500;
      cursor: pointer;
      margin: 0 auto;

      &.hide {
        visibility: hidden;
      }
    }
    .pkOptions {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .option {
        width: 3.73rem;
        font-size: 0.4rem;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 0.56rem;
        color: #3e3e3e;
        border: 1px solid #d8d8d8;
        text-align: center;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;

        .in {
          padding: 0.19rem 1rem;
          transition: all 1.5s;
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          p {
            font-size: 0.4rem;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 0.56rem;
            color: #3e3e3e;
          }
        }
        &.on {
          border: 1px solid #f04040;

          p {
            color: #f04040;
          }
          .percent {
            background-color: #feebeb;
          }
          .voteNum {
            color: #f04040;
          }
        }
      }
      .vs {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0.6rem;
        height: 0.6rem;
        background: transparent no-repeat center center;
        background-image: url("../assets/images/vs.png");
        background-size: 100% auto;
        -webkit-background-size: 100% auto;
        -moz-background-size: 100% auto;
        -ms-background-size: 100% auto;
        -o-background-size: 100% auto;
      }
      &.result {
        .option {
          .in {
            padding: 0.19rem 0.4rem;
            justify-content: flex-end;
          }
          &:first-child {
            .in {
              justify-content: flex-start;
            }
          }
        }
      }
    }
    .vsLine {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.2rem;
      visibility: hidden;

      &.show {
        visibility: visible;

        .left,
        .right {
          opacity: 1;
        }
      }
      .left {
        width: 0.66rem;
        font-size: 0.37rem;
        font-family: Segoe UI;
        font-weight: bold;
        line-height: 0.59rem;
        color: #f04040;
        flex: 1;
        transition: all 1.5s;
        opacity: 0;
      }
      .line {
        flex: 10;
        position: relative;

        div {
          background-color: #f3a7a9;
          height: 0.13rem;
          border-radius: 0.075rem;
          -webkit-border-radius: 0.075rem;
          -moz-border-radius: 0.075rem;
          -ms-border-radius: 0.075rem;
          -o-border-radius: 0.075rem;
          border-radius: 0.075rem;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: 0 0.1rem;
          width: 0%;
          transition: all 1.5s;
        }
        .leftLine {
          left: 0;
        }
        .rightLine {
          right: 0;
        }
        .on {
          background-color: #f04040;
        }
      }
      .right {
        width: 0.66rem;
        font-size: 0.37rem;
        font-family: Segoe UI;
        font-weight: bold;
        line-height: 0.59rem;
        color: #f04040;
        text-align: right;
        flex: 1;
        transition: all 1.5s;
        opacity: 0;
      }
    }
  }
</style>
